<template>
  <div class="row">
    <div class="col">
      <h3 v-if="!preloader" class="mt-5 text-center">{{ textTitle }}</h3>
      <h3 v-if="!preloader" class="mt-2 text-center">{{ subtextTitle }}</h3>
      <PreloaderComponent v-if="preloader == true" />
      <div
        id="finalsave"
        v-if="showFinalBtn === true"
        @click="finalSave()"
        class="row butn"
      >
        Wyślij zamówienie
      </div>
      <SearchBar v-if="!preloader" @search="search" />
      <ColorsListComponent
        v-if="!preloader"
        @preloader="preloader"
        @chooseProduct="chooseProduct"
        :rangeItems="slicedRangeItems"
      />
      <PaginationComponent
        v-if="!preloader"
        @paginationHandler="paginationHandler"
        @paginationHidder="paginationHidder"
        :pages="pages"
        :maxPages="maxPages"
      />
    </div>
  </div>
</template>
<script>
import PreloaderComponent from "./PreloaderComponent.vue";
import SearchBar from "./SearchBar.vue";
import ColorsListComponent from "../components/ColorsListComponent.vue";
import PaginationComponent from "../components/PaginationComponent.vue";
export default {
  name: "ChooseColorsComponent",
  components: {
    SearchBar,
    ColorsListComponent,
    PaginationComponent,
    PreloaderComponent,
  },
  props: {
    rangeItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    activatedProduct: {
      required: true,
    },
    loading: Boolean,
    textTitle: {
      default: "Wybierz produkty",
    },
    subtextTitle: String,
    showFinalBtn: Boolean,
  },
  created() {
    this.productsToChoose = this.rangeItems;
    this.fullRangeItems = this.rangeItems;
    this.preloader = this.loading;
  },
  watch: {
    loading() {
      this.preloader = this.loading;
    },
    rangeItems() {
      this.productsToChoose = this.rangeItems;
    },
  },
  data() {
    return {
      currentPage: 1,
      previousPageIndex: 1,
      productsToChoose: [],
      fullRangeItems: [],
      preloader: true,
    };
  },
  computed: {
    maxPages() {
      return Math.ceil(this.productsToChoose.length / 36);
    },
    pages() {
      let arr = new Array(Math.ceil(this.productsToChoose.length / 36));
      let index = arr.length;
      arr = arr.slice(0, index - 1);
      return arr;
    },
    to() {
      return this.currentPage * 36;
    },
    from() {
      return this.to - 36;
    },
    slicedRangeItems() {
      return this.productsToChoose.slice(this.from, this.to);
    },
  },
  methods: {
    finalSave() {
      this.$emit("finalSave");
    },
    chooseProduct(prod) {
      this.$emit("chooseColor", prod);
    },
    //wyszukiwanie
    search(param, type) {
      this.currentPage = 1;
      const prevPagEl = document.getElementById(`${this.previousPageIndex}`);
      const currentPagEl = document.getElementById(`${this.currentPage}`);
      if (prevPagEl && currentPagEl) {
        prevPagEl.classList.remove("page-active");
        currentPagEl.classList.remove("not-display");
        setTimeout(() => {
          currentPagEl.classList.add("page-active");
        }, 10);
      }
      this.previousPageIndex = this.currentPage;
      if (param.length === 0) {
        this.productsToChoose = this.fullRangeItems;
        setTimeout(() => {
          let div = document.getElementById(`${this.maxPages}`);
          div.classList.remove("page-active");
        }, 10);
      } else if (type === "press") {
        this.productsToChoose = this.fullRangeItems;
        let str = param.toLowerCase();
        this.productsToChoose = this.productsToChoose.filter((el) =>
          el.name ? el.name.toLowerCase().includes(str) : null
        );
      }
      if (this.currentPage + 3 >= this.maxPages) {
        let div = document.getElementById("pagination-dots");
        div.classList.add("not-display");
        setTimeout(() => {
          this.paginationHidder(1, this.currentPage);
        }, 10);
      } else {
        let div = document.getElementById("pagination-dots");
        div.classList.remove("not-display");
        setTimeout(() => {
          this.paginationHidder(1, 6);
        }, 10);
      }
    },
    //obsługa paginacji
    paginationHidder(pagFrom, pagTo) {
      if (pagFrom < 0) {
        pagFrom = 1;
      }
      if (this.currentPage + 2 >= this.maxPages) {
        let div = document.getElementById("pagination-dots");
        div.classList.add("not-display");
      } else {
        let div = document.getElementById("pagination-dots");
        div.classList.remove("not-display");
      }
      for (let i = pagTo; i < this.maxPages; i++) {
        let el = document.getElementById(`${i}`);
        el.parentElement.classList.add("not-display");
      }
      for (let i = 1; i < pagFrom; i++) {
        let el = document.getElementById(`${i}`);
        el.parentElement.classList.add("not-display");
      }
      for (let i = pagFrom; i < pagTo; i++) {
        let el = document.getElementById(`${i}`);
        el.parentElement.classList.remove("not-display");
      }
    },

    //obsługa paginacji
    paginationHandler(index) {
      if (index + 1 === this.currentPage) {
        return;
      }
      this.preloader = true;
      if (index === "Next") {
        if (this.currentPage === this.maxPages) {
          this.preloader = false;
          return;
        }
        this.currentPage = this.currentPage + 1;
        const paginationElement = document.getElementById(
          `${this.currentPage}`
        );
        paginationElement.classList.add("page-active");
        const prevPagEl = document.getElementById(`${this.previousPageIndex}`);
        prevPagEl.classList.remove("page-active");
        this.previousPageIndex = this.currentPage;
      } else if (index === "Prev") {
        if (this.currentPage === 1) {
          this.preloader = false;
          return;
        }
        this.currentPage = this.currentPage - 1;
        const paginationElement = document.getElementById(
          `${this.currentPage}`
        );
        paginationElement.classList.add("page-active");
        const prevPagEl = document.getElementById(`${this.previousPageIndex}`);
        prevPagEl.classList.remove("page-active");
        this.previousPageIndex = this.currentPage;
      } else {
        this.currentPage = index + 1;
        const paginationElement = document.getElementById(`${index + 1}`);
        paginationElement.classList.add("page-active");
        const prevPagEl = document.getElementById(`${this.previousPageIndex}`);
        prevPagEl.classList.remove("page-active");
        this.previousPageIndex = index + 1;
      }
      if (this.currentPage >= 3 && this.currentPage <= this.maxPages - 3) {
        this.paginationHidder(this.currentPage - 1, this.currentPage + 2);
      } else if (this.currentPage > this.maxPages - 3) {
        this.paginationHidder(this.currentPage - 1, this.maxPages);
      } else if (this.currentPage < 3) {
        this.paginationHidder(1, 4);
      }
      this.preloader = false;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
#pagination-container {
  border-top: 1px solid #1ab394;
  border-bottom: 1px solid #1ab394;
  width: fit-content;
}
.pagination {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pagination-element {
  text-align: center;
  width: fit-content;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 3px;
  border-radius: 1px;
  text-decoration: none;
  color: black;
  padding: 3px 5px 0 5px;
}
.page-active {
  background-color: #1ab394;
  color: white;
}
.not-display {
  display: none;
}
.butn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: center;
  color: #fff;
  background-color: #1ab394;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  margin: 30px auto;
}
.butn:hover {
  background-color: #18a689;
  transform: scale(1.01);
  transition: all 0.5s ease-in-out;
}
</style>
