<template>
  <div class="row center">
    <div
      v-for="(product, index) in rangeItems"
      :key="'setProd' + index"
      @click="chooseProduct(product)"
      class="col col-sm-6 col-md-4 col-lg-2"
      :id="'setProd' + index"
    >
      <ImageComponent :name="product.name" :img="product.image" />
    </div>
  </div>
</template>

<script>
import ImageComponent from "../components/ImageComponent.vue";
export default {
  name: "ColorListComponent",
  components: {
    ImageComponent,
  },
  props: {
    rangeItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    preloader(value) {
      this.$emit("preloader", value);
    },
    chooseProduct(prod) {
      this.$emit("chooseProduct", prod);
    },
  },
};
</script>

<style scoped>
@media (max-width: 400px) {
  .center {
    justify-content: center !important;
  }
}
</style>
