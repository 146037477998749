<template>
  <div class="modal" :class="showModal ? 'show' : ''">
    <div class="modal-content">
      <span class="close" @click="closeModal()">&times;</span>
      <p>Wygląda na to, że zostały wybrane wszystkie produkty</p>
      <p>
        Możesz zapisać swoje wybory i przejść do następnego produktu lub wybrać
        ponownie.
      </p>
      <p>Pamiętaj jednak żeby zapisać ostateczny wybór.</p>
      <span class="btn1 save" @click="sendProductList()">Zapisz</span>
      <span class="btn1 back" @click="closeModal()">Wróć</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  props: {
    modalIsShow: {
      type: Boolean,
    },
  },
  watch: {
    modalIsShow() {
      this.showModal = this.modalIsShow;
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    sendProductList() {
      this.$emit("sendProductList");
    },
  },
};
</script>

<style scoped>
.btn1 {
  width: 20vw;
  min-width: 150px !important;
  text-align: center;
  margin: 5px;
}
.close {
  position: absolute;
  top: 1px;
  right: 10px;
}
.modal-content {
  display: flex;
  align-items: center;
  text-align: center;
}
</style>
