<template>
  <div class="container">
    <div v-if="showAll === true">
      <OrderComponent @setProd="setListOfProducts" :products="products" />
      <ProductsComponent
        v-if="activatedSetToConfigure.length !== 0"
        :activatedSetToConfigure="activatedSetToConfigure"
        @setActiveProduct="setActiveProduct"
        @sendBoxesArray="sendBoxesArray"
      />
      <ChooseColorsComponent
        v-if="activatedProd && filteredRangeItems.length > 0"
        @chooseColor="chooseColor"
        @finalSave="finalSave"
        :rangeItems="filteredRangeItems"
        :activatedProduct="activatedProd"
        :loading="loading"
        :showFinalBtn="showFinalBtn"
        :textTitle="textTitle"
        :subtextTitle="subtextTitle"
      />
      <ModalComponent
        @sendProductList="sendProductList"
        :modalIsShow="modalIsShow"
      />
    </div>
    <ThankYouPage :products="products.orderProducts" v-if="!showAll" />
  </div>
</template>
<script>
import OrderComponent from "../components/OrderComponent.vue";
import ProductsComponent from "../components/ProductsComponent.vue";
import ChooseColorsComponent from "../components/ChooseColorsComponent.vue";
import ModalComponent from "../components/ModalComponent.vue";
import ThankYouPage from "../components/ThankYouPage.vue";
export default {
  name: "AllegroColorPicker",
  components: {
    OrderComponent,
    ProductsComponent,
    ChooseColorsComponent,
    ModalComponent,
    ThankYouPage,
  },
  data() {
    return {
      products: [],
      itemsToConfigure: null,
      orderId: null,
      activatedSetToConfigure: [],
      activatedProd: {},
      boxesArray: [],
      prodName: "",
      filteredRangeItems: [],
      loading: false,
      modalIsShow: false,
      productsSet: null,
      showFinalBtn: false,
      textTitle: "Wybierz produkty",
      subtextTitle: "",
      productsToSet: null,
      showAll: true,
    };
  },
  created() {
    this.getAuth();
  },
  watch: {
    productsSet() {
      if (this.productsSet === this.productsToSet) {
        this.showFinalBtn = true;
        this.textTitle = "Rozmyśliłeś się? W takim razie wybierz inne kolory.";
        this.subtextTitle =
          "Jeśli wszystko jest w porządku, wyślij zamówienie.";
      }
    },
  },
  methods: {
    chooseColor(prod) {
      this.modalIsShow = false;
      this.activatedProd.plum = prod.plum;
      this.activatedProd.image = prod.image;
      this.activatedProd.name = prod.name;
      let elementToScroll = document.querySelector(".myheading");
      elementToScroll.scrollIntoView({ behavior: "smooth" });
      let indexInSetConf = this.activatedProd.indexOf;
      let itemsConfigured = 0;
      let numberOfProducts =
        this.activatedSetToConfigure.setConfiguration[indexInSetConf].products
          .length;
      this.activatedSetToConfigure.setConfiguration[
        indexInSetConf
      ].products.forEach((element) => {
        if (element.plum !== "" && element.plum !== null) {
          itemsConfigured += 1;
        }
      });
      //wchodzimy do nowego wyboru
      let nextProd;
      this.activatedSetToConfigure.setConfiguration[
        indexInSetConf
      ].products.forEach((el) => {
        if (el.plum === null) {
          nextProd = el;
          return;
        }
      });
      if (nextProd) {
        this.setActiveProduct(nextProd);
      }

      if (itemsConfigured >= numberOfProducts) {
        this.modalIsShow = false;
        setTimeout(() => {
          this.modalIsShow = true;
        }, 50);
      }
    },

    setActiveProduct(activatedProd) {
      this.loading = true;
      this.filteredRangeItems = [];
      let arr = activatedProd.rangeItems.map((a) => a.itemCode);
      this.$http
        .get(
          `https://allegro-product-picker.neonail.pl/neonail/pl/images?plums=${arr}`
        )
        .then((resp) => {
          this.filteredRangeItems = resp.data.filter(
            (el) => el.plum !== "" && el.visible !== ""
          );
          setTimeout(() => {
            this.loading = false;
          }, 100);
        })
        .catch((err) => {
          console.log("wystąpił problem przy pobieraniu", err.response);
        });
      setTimeout(() => {
        this.activatedProd = activatedProd;
      }, 10);
    },

    setListOfProducts(prod) {
      this.activatedSetToConfigure = [];
      this.activatedProd = {};
      this.boxesArray = [];
      this.filteredRangeItems = [];
      this.modalIsShow = false;
      this.$forceUpdate();
      this.activatedSetToConfigure = prod;
      let next;
      prod.setConfiguration.forEach((el) => {
        if (el.rangeItems.length !== 0) {
          next = el.products[0];
        }
      });
      // let next = prod.setConfiguration[0].products[0];
      this.setActiveProduct(next);
    },

    sendBoxesArray(boxesArray) {
      this.boxesArray = boxesArray;
    },

    sendProductList() {
      let url = window.location.hash;
      let url1 = url.substr(2, url.length);
      let params = new URLSearchParams(url1);
      let orderId = params.get("orderId");
      let indexInSetConf = this.activatedProd.indexOf;
      let currentProduct =
        this.activatedSetToConfigure.setConfiguration[indexInSetConf];
      let quantity = currentProduct.quantity;
      let chosenProducts = [];
      currentProduct.products.map((x) => {
        chosenProducts.push(x.plum);
      });
      let attr = chosenProducts.toString().split(",").join(";");
      let dane = {
        orderId: Number(orderId),
        blOrderProductId: this.activatedSetToConfigure.blOrderProductId,
        attributes: attr,
      };
      if (chosenProducts.length == quantity) {
        this.$http.post(`Order/SetOrderProductFields`, dane).then(() => {
          //tutaj możnaby po zapisaniu wborów odrazu rzucać inny zestaw
          // setListOfProducts()
          if (currentProduct.send !== true) {
            this.productsSet += 1;
            currentProduct.send = true;
          }
          this.$forceUpdate();
        });
      } else {
        alert("wybierz poprawną ilość");
      }
      this.modalIsShow = false;
    },

    finalSave() {
      let dane = {
        orderId: Number(this.products.orderId),
        blStatusId: 279560,
      };
      if (this.productsSet === this.productsToSet) {
        this.$http.post(`Order/SetOrderStatus`, dane).then((resp) => {
          console.log(resp);
          // this.$router.push("/thankyou");
          this.showAll = false;
        });
        this.hideAll = true;
      } else
        alert(
          "zostały jeszcze przedmioty do skonfigurowania! Upewnij się, że wybrałeś poprawną ilość kolorów"
        );
    },

    //uwierzytelnianie
    getParamsFromURL() {
      let url = window.location.hash;
      let url1 = url.substr(2, url.length);
      let params = new URLSearchParams(url1);
      return params;
    },

    getAuth() {
      let params = this.getParamsFromURL();
      let mail;
      let email = params.get("email");
      if (email !== "") {
        mail = email.replace(" ", "+");
      }
      let orderId = params.get("orderId");
      this.orderId = orderId;
      let dane = {
        email: mail,
        orderId: Number(orderId),
        url: "adad",
      };
      this.$http.post("Auth/Authenticate", dane).then((resp) => {
        this.$http.defaults.headers.common["Authorization"] =
          "Bearer " + resp.data.token;
        this.getProducts();
      });
    },

    //metoda pobierająca zamówienie
    getProducts() {
      this.$http
        .get(`Order/GetOrder?OrderId=${this.orderId}`)
        .then((resp) => {
          this.products = resp.data;
          this.products.orderProducts.forEach((element) => {
            element.setConfiguration.forEach((el) => {
              if (el.type == "RANGE" && el.rangeItems.length !== 0) {
                let nr = el.quantity;
                this.itemsToConfigure += nr;
                this.productsToSet += 1;
              }
            });
          });
        })
        .catch((err) => {
          if (
            err.response.data.statusCode == "500" &&
            err.response.data.message ==
              "Object reference not set to an instance of an object."
          ) {
            console.log("W zamówieniu nie ma kolorów do wyboru");
          } else console.log(err.response, "erorr");
        });
    },
  },
};
</script>
