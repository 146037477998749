import Vue from "vue";
import VueRouter from "vue-router";
import ThankYouPage from "../components/ThankYouPage.vue";

Vue.use(VueRouter);

const routes = [{ path: "/thankyou", component: ThankYouPage }];

const router = new VueRouter({
  routes,
});

export default router;
