<template>
  <div class="row">
    <h1 class="myheading">Wybierz zestaw do skompletowania</h1>
    <div
      v-for="(product, index) in productsArray"
      :key="'product' + index"
      class="col col-sm-6 col-md-4 col-lg-2"
      @click="setToActive(product)"
      :style="
        product.setConfiguration ? 'cursor: pointer' : 'cursor: not-allowed'
      "
    >
      <SingleOrderComponent :itemName="product.itemName" />
    </div>
  </div>
</template>
<script>
import SingleOrderComponent from "../components/SingleOrderComponent.vue";
export default {
  name: "OrderComponent",
  components: {
    SingleOrderComponent,
  },
  props: {
    products: {
      required: true,
    },
  },
  data() {
    return {
      productsArray: [],
    };
  },
  watch: {
    products() {
      this.fillProducts();
      this.products.orderProducts.forEach((el) => {
        if (el.isSet === true) {
          let stringAr = el.itemName.split(" ");
          stringAr[1] = stringAr[1].toLowerCase();
          let name = stringAr.join(" ");
          el.itemName = name;
          this.productsArray.push(el);
        }
      });
    },
  },
  methods: {
    setToActive(prod) {
      this.$emit("setProd", prod);
    },
    makeQuantity(element) {
      let quantity = 0;
      if (element.type === "RANGE") {
        switch (element.rangeOpt) {
          case "M":
            quantity = element.quantity;
            break;
          case "S":
            quantity = 1;
            break;
        }
      }
      return quantity;
    },
    fillProducts() {
      this.products.orderProducts.forEach((el) => {
        el.setConfiguration.forEach((element, index) => {
          let quantity = this.makeQuantity(element);
          element.products = [];
          for (let i = 0; i < quantity; i++) {
            element.products.push({
              plum: null,
              image: "",
              indexOf: index,
              indexInBoxArr: i,
              name: null,
              rangeItems: element.rangeItems,
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.myheading {
  text-align: center;
  margin-bottom: 50px;
}
</style>
