<template>
  <div class="firstChoice">
    {{ itemName }}
  </div>
</template>
<script>
export default {
  name: "SingleOrderComponent",
  props: {
    itemName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.firstChoice {
  border: 1px solid black;
  width: 100%;
  text-align: center;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 3px;
  margin-bottom: 10px;
}
.firstChoice:hover {
  animation: 1s boxHover forwards;
  transform: scale(1.05);
  transition: 0.5s ease-in-out;
}
@keyframes boxHover {
  from {
    border: 1px solid black;
    color: black;
    transform: scale(1);
  }
  to {
    border: 1px solid rgb(75, 123, 75);
    transform: scale(1.05);
  }
}
</style>
