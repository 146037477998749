<template>
  <figure class="preloader" id="preloader">
    <div class="circles-group">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
  </figure>
</template>

<script>
export default {
  name: "PreloaderComponent",
};
</script>

<style scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  /* visibility: hidden; */
  /* opacity: 0; */
  width: 90%;
  margin: 0;
  top: 85%;
  left: 5%;
  transition: opacity 400ms ease-in-out, visibility 0s 400ms;
}
@media (max-width: 400px) {
  .preloader {
    top: 95%;
  }
}

.show-preloader {
  visibility: visible;
  opacity: 1;
}

.circle {
  background-color: black;
  height: 10px;
  width: 10px;
  margin: 2px;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: stretchdelay 0.7s infinite ease-in-out;
  animation: stretchdelay 0.7s infinite ease-in-out;
}

.preloader .circle:nth-child(1) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.preloader .circle:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.preloader .circle:nth-child(3) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.preloader .circle:nth-child(4) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
  }
  20% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
}
</style>
