<template>
  <div id="app">
    <AllegroColorPicker />
  </div>
</template>

<script>
import AllegroColorPicker from "@/views/AllegroColorPicker.vue";
export default {
  name: "app",
  components: {
    AllegroColorPicker,
  },
};
</script>
