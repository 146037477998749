<template>
  <div class="row">
    <h2 class="myheading">Wybierz swoje lakiery hybrydowe</h2>
    <div
      class="col col-sm-6 col-md-4 col-lg-2"
      v-for="(product, index) in boxesArray"
      :key="'product' + index"
      @click="setToActiveProduct(product)"
    >
      <div class="box">
        <ImageComponent :img="product.image" :additionalContent="false" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "../components/ImageComponent.vue";
export default {
  name: "ProductsComponent",
  components: {
    ImageComponent,
  },
  props: {
    activatedSetToConfigure: {
      required: true,
    },
  },
  data() {
    return {
      boxesArray: [],
      activeProduct: {},
      stringsArray: [],
    };
  },
  created() {
    this.fillBoxesArray();
  },
  watch: {
    activatedSetToConfigure() {
      this.fillBoxesArray();
    },
  },
  methods: {
    setToActiveProduct(activeProd) {
      this.activeProduct = activeProd;
      this.$emit("setActiveProduct", activeProd);
    },
    makeQuantity(element) {
      let quantity = 0;
      if (element.type === "RANGE") {
        switch (element.rangeOpt) {
          case "M":
            quantity = element.quantity;
            break;
          case "S":
            quantity = 1;
            break;
        }
      }
      return quantity;
    },
    fillBoxesArray() {
      this.boxesArray = [];
      this.activatedSetToConfigure.setConfiguration.forEach((element) => {
        let quantity = this.makeQuantity(element);
        for (let i = 0; i < quantity; i++) {
          this.boxesArray = [...element.products];
        }
      });
    },
  },
};
</script>

<style scoped>
.box {
  border: 1px solid black;
  text-align: center;
  border-radius: 1px;
  margin: 10px;
  cursor: pointer;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.box:hover {
  animation: 1s boxHover;
  color: rgb(75, 123, 75);
  border: 1px solid rgb(75, 123, 75);
  transform: scale(1.05);
  transition: 0.5s ease-in-out;
}
@keyframes boxHover {
  from {
    border: 1px solid black;
    color: black;
  }
  to {
    color: rgb(75, 123, 75);
    border: 1px solid rgb(75, 123, 75);
  }
}
.myheading {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
</style>
