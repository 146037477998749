<template>
  <div class="row centered">
    <div class="col">
      <div id="pagination-container" class="row">
        <div class="pagination col">
          <a
            style="width: fit-content"
            class="pagination-element"
            @click="paginationHandler('Prev')"
            >Prev</a
          >
        </div>
        <div
          v-for="(page, index) in pages"
          :key="'page' + index"
          class="pagination col"
        >
          <a
            class="pagination-element"
            :class="index === 0 ? 'page-active' : ''"
            :id="index + 1"
            @click="paginationHandler(index)"
          >
            {{ index + 1 }}
          </a>
        </div>
        <div id="pagination-dots" class="pagination col">
          <a class="pagination-element">...</a>
        </div>
        <div class="pagination col">
          <a
            class="pagination-element"
            :id="maxPages"
            :class="maxPages === 1 ? 'page-active' : ''"
            @click="paginationHandler(maxPages - 1)"
          >
            {{ maxPages }}
          </a>
        </div>
        <div class="pagination col">
          <a
            style="width: fit-content"
            class="pagination-element"
            @click="paginationHandler('Next')"
            >Next</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    maxPages: {
      type: Number,
      required: true,
    },
    pages: {
      required: true,
    },
  },
  mounted() {
    this.paginationHidder(1, 4);
  },
  methods: {
    paginationHandler(type) {
      this.$emit("paginationHandler", type);
    },
    paginationHidder(pagFrom, pagTo) {
      this.$emit("paginationHidder", pagFrom, pagTo);
    },
  },
};
</script>

<style scoped>
.centered {
  width: fit-content;
  margin: 10px auto;
}
#pagination-container {
  border-top: 1px solid #1ab394;
  border-bottom: 1px solid #1ab394;
  width: fit-content;
  padding: 10px 50px;
}
.pagination {
  display: flex;
  flex-flow: row;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pagination-element {
  text-align: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin: 3px;
  border-radius: 1px;
  text-decoration: none;
  color: black;
  padding: 3px 5px 0 5px;
}
.page-active {
  background-color: #1ab394;
  color: white;
}
.not-display {
  display: none;
}
@media (max-width: 500px) {
  .pagination-element {
    width: fit-content;
    margin: 0;
  }
  #pagination-container {
    padding: 10px 5px;
  }
  .pagination {
    padding: 3px;
  }
}
</style>
