<template>
  <div class="row mt-bot">
    <div class="mt-2 mb-5">
      <input
        class="my-input"
        type="text"
        placeholder="wyszukaj kolor"
        v-model="searchingParam"
        @input="search(searchingParam, 'change')"
        @keyup.enter="search(searchingParam, 'press')"
      />
      <button @click.prevent="search(searchingParam, 'press')" class="my-btn">
        wyszukaj
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      searchingParam: "",
    };
  },
  methods: {
    search(searchingParam, type) {
      this.$emit("search", searchingParam, type);
    },
  },
};
</script>
<style scoped>
.my-btn {
  padding: 5px 10px;
  background-color: #1ab394;
  border-color: #1ab394;
  cursor: pointer;
  color: white;
  border-radius: 0 3px 3px 0;
}
.my-btn:hover {
  background-color: #18a689;
  border-color: #18a689;
}
.my-input {
  width: 20wv;
  padding: 5px;
  margin: 10px 0 10px 10px;
  border-radius: 3px 0 0 3px;
}
</style>
