<template>
  <div
    v-if="visible === 'on'"
    :class="additionalContent ? 'kolor-hover' : 'kolor'"
  >
    <img class="inner-img" :src="img" alt="" />
    <img
      v-if="img == '' && !additionalContent"
      src="../assets/plus-icon.png"
      alt=""
    />
    <p v-if="additionalContent || end">{{ name }}</p>
  </div>
</template>

<script>
export default {
  name: "ImageComponent",
  props: {
    name: String,
    img: String,
    additionalContent: {
      default: true,
    },
    end: {
      default: false,
    },
  },
  data() {
    return {
      imgSrc: "",
      imgName: "",
      visible: "on",
    };
  },
  // watch: {
  //   plum() {
  //     this.getImage();
  //   },
  // },
  methods: {
    getImage() {
      if (this.plum !== null && this.plum !== undefined) {
        this.$emit("preloader", true);
        this.$http
          .get(
            `http://allegro-product-picker.neonail.pl/neonail/pl/images/${this.plum}`
          )
          .then((resp) => {
            this.visible = resp.data.visible;
            this.imgSrc = resp.data.image;
            this.imgName = resp.data.name;
            this.$emit("preloader", false);
          });
      } else {
        this.imgSrc = "";
        this.imgName = "";
      }
    },
  },
};
</script>

<style scoped>
.kolor,
.kolor-hover {
  margin: 10px;
  padding: 10px;
  border-radius: 1px;
  width: 90%;
  height: auto;
  text-align: center;
  border: 1px solid transparent;
}
.kolor-hover:hover {
  animation: 1s colorHover forwards;
  border: 1px solid black;
  animation-fill-mode: forwards;
  cursor: pointer;
}
@keyframes colorHover {
  from {
    border: 1px solid black;
    transform: scale(1);
  }
  to {
    border: 1px solid rgb(75, 123, 75);
    transform: scale(1.05);
  }
}
.inner-img {
  width: 100%;
  max-width: 200px;
}
</style>
