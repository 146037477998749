<template>
  <div class="row" style="justify-content: center">
    <h1 class="text-center mb-3">
      Dziękujemy! <br />
      Twoje zamówienie zostało wysłane.
    </h1>
    <h3 class="mb-3 text-center">Kolory które wybrałeś to:</h3>
    <div
      class="col-12 col-sm-6 col-md-4 col-lg-2"
      v-for="(color, index) in colors"
      :key="'kolor' + index"
    >
      <div class="kolor">
        <img class="inner-img" :src="color.image" alt="" />
        <p>{{ color.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouPage",
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      prods: [],
      colors: [],
    };
  },
  created() {
    this.prods = this.products;
    this.extractProducts();
  },
  methods: {
    extractProducts() {
      this.prods.forEach((el) => {
        el.setConfiguration.forEach((prod) => {
          if (prod.type === "RANGE" && prod.rangeItems.length > 0) {
            prod.products.forEach((element) => {
              this.colors.push({
                name: element.name,
                image: element.image,
              });
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped>
.kolor {
  margin: 10px;
  padding: 10px;
  border-radius: 1px;
  width: 155px;
  height: auto;
  text-align: center;
  border: 1px solid transparent;
}
.inner-img {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 400px) {
  .kolor {
    width: 90%;
  }
}
</style>
